import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import BackgroundImg from '../../../assets/about-2-images/stats.jpg'

export const StatsSection = styled.section`
    padding:100px 0px 70px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const StatsLayout = styled.div`

`;

export const SingleStatLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:30px;

    @media ${device.mobileXL} {
        flex-direction:column;
    }
`;

export const StatImage = styled.img`
    height:70px;
    flex-shrink:0;

    @media ${device.mobileXL} {
        height:60px;
        margin-bottom:10px;
    }
`;

export const TextLayout = styled.div`
    margin-left:20px;

    @media ${device.mobileXL} {
        margin-left:0px;
    }
`;

export const StatHeading = styled.h1`
    color:#fff;
    line-height:1;
    margin-bottom:5px;

    @media ${device.laptop} {
        line-height:1;
    }
    @media ${device.mobileXL} {
        line-height:1;
        text-align:center;
    }
`;

export const StatPara = styled.h6`
    color:#fff;
    margin-bottom:0;

    @media ${device.mobileXL} {
        text-align:center;
    }
`;

import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import 'bootstrap/dist/css/bootstrap.min.css';

export const MissionSection = styled.section`

`;

export const SectionWrapper = styled.div`
    padding:0px 0px 100px 0px;

    @media ${device.tablet} {
        padding:0px 5px 80px;
        hr{
            margin-bottom:80px;
        }
    }

    @media ${device.tablet} {
        hr{
            margin:0px;
            margin-bottom:100px;
        }
    }

`;


export const HeadingLayout = styled.div`
    margin-bottom:30px;
    h5{
        color:#6f6f6f;
    }

`;

export const Description = styled.p`
`;

export const ServicesLayout = styled.div`
    display:flex;
    align-items:center;
    margin-top:10px;

    @media ${device.mobileXL}{
        margin-bottom:10px;
    }
`;

export const ServicesImg = styled.img`
    width:50px;
    height:50px;
`;

export const ServicesTextLayout = styled.div`
    margin-left:10px;

    p{
        margin-bottom:0px;
    }
`;


export const ImageHolder = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
        margin-top:40px;
    }

    @media ${device.mobileXL}{
        margin-top:30px;
    }
`;



export const LeftLayout = styled.div`
    width: 60%;
    background: #fafafa;
    position: relative;
    margin-right: -100px;
    padding: 30px;
    border-radius: 5px;
    z-index: 1;
    border: 1px solid #ddd;

    @media ${device.laptop} {
        width:100%;
        margin-right:0px;
    }
`;

export const RightLayout = styled.div`
    width:50%;
    flex-shrink:0;

    img{
        width: 100%;
        height: 580px;
        border-radius: 5px;
    }

    @media ${device.laptop} {
        width:100%;
    }
`;

export const MissionWrapper = styled.div`
    display:flex;
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

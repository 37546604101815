import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import BackgroundImg from '../assets/about-2-images/banner.jpg';
import Features from "../containers/AboutPage2/Features"
import Stats from "../containers/AboutPage2/Stats"
import Team from "../containers/AboutPage2/Team"
import Mission from "../containers/AboutPage2/Mission"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";

const AboutPage2 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="About Our Company"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="About"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/aboutus-2"
        />
        <Features />
        <Stats />
        <Team />
        <Mission />
        <Footer templateType="type2"/>
    </Layout>
)

export default AboutPage2;
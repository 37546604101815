import React from 'react';
import {MissionSection,SectionWrapper,LeftLayout,HeadingLayout,
    Description,ServicesLayout,ServicesImg,
    ServicesTextLayout,ImageHolder,RightLayout,MissionWrapper
} from './mission.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Mission = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                aboutPage2Json{
                    Mission{
                       Heading
                       SubHeading
                       Description
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       ServicesImg1
                       ServicesDesc1
                       ServicesImg2
                       ServicesDesc2
                    }
                }
            }
    `);
    const MissionData = JSONData.aboutPage2Json.Mission;

    return (
        <MissionSection id="MissionSection">
            <SectionWrapper>
                <Container>
                    <MissionWrapper>
                        <LeftLayout>
                            <HeadingLayout>
                                <h2>
                                    {MissionData.Heading}
                                </h2>
                                <h5>
                                    {MissionData.SubHeading}
                                </h5>
                            </HeadingLayout>
                            <Description>
                            {MissionData.Description}
                            </Description>

                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <ServicesLayout>
                                        <ServicesImg
                                            src={MissionData.ServicesImg1}
                                            alt=""
                                        />
                                        <ServicesTextLayout>
                                            <p>
                                            {MissionData.ServicesDesc1}
                                            </p>
                                        </ServicesTextLayout>
                                    </ServicesLayout>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <ServicesLayout>
                                        <ServicesImg
                                            src={MissionData.ServicesImg2}
                                            alt=""
                                        />
                                        <ServicesTextLayout>
                                            <p>
                                            {MissionData.ServicesDesc2}
                                            </p>
                                        </ServicesTextLayout>
                                    </ServicesLayout>
                                </Col>
                            </Row>
                        </LeftLayout>
                        <RightLayout>
                            <ImageHolder>
                                <GatsImg
                                    fluid={MissionData.Img.childImageSharp.fluid}
                                    alt=""
                                />
                            </ImageHolder>
                        </RightLayout>
                    </MissionWrapper>
                </Container>
            </SectionWrapper>
        </MissionSection>
    );
}

export default Mission;

import React from 'react';
import {FeaturesSection,FeaturesInner,CustomRow,FeaturesRightCol,
    FeaturesRightLih3,FeaturesRightLipara,
    FeaturesRightWrapper,FeaturesRight,FeaturesLeft,FeaturesImgCol,
    FeaturesLiContentCol} from './features.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            aboutPage2Json {
                Features {
                    FeaturesHeading
                    FeaturesLeftImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    FeaturesPlayBtn
                    FeaturesRightUl{
                        FeaturesIcon
                        FeaturesRightLih3
                        FeaturesRightLipara
                    }
                }
            }
        }
    `);
    const FeaturesData = JSONData.aboutPage2Json.Features;
    return (
        <FeaturesSection id="FeaturesContainer">
            <Container>
                <FeaturesInner>
                    <CustomRow>
                        <Col lg="6" md="12">
                                <FeaturesLeft>
                                    <GatsImg
                                        fluid={FeaturesData.FeaturesLeftImg.childImageSharp.fluid}
                                        className="FeaturesLeftImg"
                                        alt=""
                                    />
                                </FeaturesLeft>
                        </Col>
                        <Col lg="6" md="12">
                            <FeaturesRight>
                                <FeaturesRightWrapper>
                                {
                                    FeaturesData.FeaturesRightUl.map((item,idx) => {
                                    return  <FeaturesRightCol>
                                            <FeaturesImgCol>
                                                <img alt="" src={item.FeaturesIcon} />
                                            </FeaturesImgCol>
                                            <FeaturesLiContentCol>
                                                <FeaturesRightLih3>{item.FeaturesRightLih3}</FeaturesRightLih3>
                                                <FeaturesRightLipara>{item.FeaturesRightLipara}</FeaturesRightLipara>
                                            </FeaturesLiContentCol>
                                        </FeaturesRightCol>

                                    })
                                }
                                </FeaturesRightWrapper>
                            </FeaturesRight>
                        </Col>
                    </CustomRow>
                </FeaturesInner>
            </Container>
        </FeaturesSection>
    );
}

export default Features;

import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {Row} from 'react-bootstrap';

export const FeaturesSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const FeaturesInner = styled.div`
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const FeaturesLeft = styled.div`
    margin-right:0px;

    @media ${device.laptop} {
        margin-right:0px;
    }

    .FeaturesLeftImg {
        border-radius:10px;
    }
`;

export const FeaturesRight = styled.div`
    margin-left:0px;

    @media ${device.laptop} {
        margin-top: 40px;
        margin-left:0px;
    }
`;

export const FeaturesRightWrapper = styled.div`
    margin-left:0px;
`;

export const FeaturesRightCol = styled.div`
    margin-bottom:30px;
    display:flex;
    align-items:center;
    border-radius:4px;
    padding: 20px;
    background: #fafafa;
    border: 1px solid #ddd;

    :last-child{
        margin-bottom:0px;
    }

    @media ${device.mobileL} {
        flex-direction:column;
        align-items:flex-start;
    }
`;

export const FeaturesImgCol = styled.div`
    width: 80px;
    flex-shrink: 0;

    img{
        width: 100%;
    }

    @media ${device.mobileL} {
        margin-bottom:15px;
    }

`;

export const FeaturesLiContentCol = styled.div`
    margin-left:20px;

    @media ${device.mobileL} {
        margin-left:0px;
    }
`;


export const FeaturesRightLih3 = styled.h5`
    text-align:left;
    margin-bottom:0px;
`;

export const FeaturesRightLipara = styled.p`
    margin-bottom:0px;
`;

import styled from 'styled-components';
import {SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const TeamSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet}{
        padding:80px 5px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:55px;

    @media ${device.tablet} {
        margin-bottom:40px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:10px;
    text-align: center;
`;

export const TeamSlider = styled.div`
    margin-left:-15px;
    margin-right:-15px;
`;

export const TeamSliderWrapper = styled.div`
    outline:0;

    :focus{
        outline:0;
    }
`;

export const TeamSliderLayout = styled.div`
    margin:15px;
`;

export const TeamImgFigure = styled.figure`
    overflow: hidden;
    margin-bottom:0px;
    position:relative;
    width:100%;

    :hover .teamImg{
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    .teamImg{
        height:320px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;

export const TeamInnerLayout = styled.div`
    position:relative;

    h5{
        margin-bottom:0px;
    }
    p{
        margin-bottom:0px;
    }
`;

export const TeamDetails = styled.div`
    position:absolute;
    bottom: 20px;
    background:#fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 0px;
    padding: 10px 40px;
`;

export const TeamSocialLayout = styled.div`
    text-align:center;
`;


export const TeamSocial = styled.a`
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block;
    color: #ff3c1c;
    background: #ffd8d1;
    border-radius: 100%;
    line-height: 24px;
    margin: 10px 3px;
    transition: all .5s;

    svg{
        width: 20px;
        color: #ff3c1c;
        transition:all .5s;
    }
    :hover svg{
        transform:rotate(30deg);
        transition:all .5s;
        color: #ff3c1c;
    }

`;

export const FbIcon = styled(SocialFacebook)`

`;

export const TwitterIcon = styled(SocialTwitter)`

`;

export const LinkedinIcon = styled(SocialLinkedin)`

`;

export const InstaIcon = styled(SocialInstagram)`

`;

export const NextPrev = styled.div`
    text-align:center;
    line-height:0px;
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    cursor:pointer;
    width: 40px;
    transition:all .4s;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.tablet} {
        height:20px;
    }
`;

export const NextImg = styled.img`
    cursor:pointer;
    transition:all .4s;
    width: 40px;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.tablet} {
        height:20px;
    }
`;

import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {StatsSection,
    StatsLayout,
    SingleStatLayout,
    StatImage,
    TextLayout,
    StatHeading,
    StatPara
} from './stats.style';

import { useStaticQuery, graphql } from 'gatsby';

const Stats = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutPage2Json{
            Stats{
                OurProjectsHeading
                StatDetails{
                    StatImage
                    StatHeading
                    StatPara
                }

            }
        }
    }
  `);
  const StatsData = JSONData.aboutPage2Json.Stats;

    return(
        <StatsSection id="statsSection">
            <Container>
                <StatsLayout>
                    <Row>
                        {
                            StatsData.StatDetails.map((statsItem, statsIdx) => {
                            return <Col lg={3} md={6} sm={6} xs={6}>
                                <SingleStatLayout>
                                    <StatImage src={statsItem.StatImage} alt=""/>
                                    <TextLayout>
                                    <StatHeading>{statsItem.StatHeading}</StatHeading>
                                    <StatPara>{statsItem.StatPara}</StatPara>
                                    </TextLayout>
                                </SingleStatLayout>
                            </Col>
                            })
                        }
                    </Row>
                </StatsLayout>
            </Container>
        </StatsSection>

    );
}

export default Stats;